@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.trust-markers-container {
  .trust-markers-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: fn.spacing(6) fn.spacing(2);
    text-align: center;

    .trust-markers-item-eyebrow {
      padding-bottom: 0;
    }

    .trust-markers-title {
      color: var.$color-primary-achieve-blue;
      padding-bottom: fn.spacing(2);
    }

    .trust-markers-markers {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 425px;

      .trust-markers-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: fn.spacing(4);
        padding-bottom: fn.spacing(6);
        border-bottom: 1px solid var.$color-neutral-grey-2;

        .trust-markers-item-title {
          color: var.$color-primary-achieve-blue;
          margin-top: fn.spacing(2);
        }

        .trust-markers-item-subtitle {
          margin-top: fn.spacing(2);
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &[data-marker-style='black1'] {
      padding: fn.spacing(0) fn.spacing(4);

      .trust-markers-markers {
        .trust-markers-item {
          .trust-markers-item-title {
            margin-top: 0;
            color: var.$color-neutral-text-onlight;
          }

          .trust-markers-item-subtitle {
            margin-top: 0;
          }
        }
      }
    }

    &[data-marker-style='blue1'] {
      padding: fn.spacing(6) fn.spacing(2);

      .trust-markers-subtitle {
        color: var.$color-neutral-white;
      }

      .trust-markers-markers {
        .trust-markers-item {
          padding-bottom: fn.spacing(4);
          border-bottom: 1px solid var.$color-primary-neutral-blue;

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .trust-markers-item-title {
            color: var.$color-neutral-white;
          }

          .trust-markers-item-subtitle {
            margin-top: 0;
            color: var.$color-neutral-white;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: var.$breakpoint-lg) {
  .trust-markers-container {
    .trust-markers-content {
      .trust-markers-markers {
        flex-direction: row;
        width: auto;
        max-width: initial;

        .trust-markers-item {
          border-bottom: 0;
          border-right: 1px solid var.$color-neutral-grey-2;
          padding: 0 fn.spacing(6);

          &:last-child {
            border-right: 0;
          }
        }
      }

      &[data-marker-style='blue1'] {
        padding: fn.spacing(8) fn.spacing(2);

        .trust-markers-markers {
          .trust-markers-item {
            border-bottom: 0;
            border-right: 1px solid var.$color-primary-neutral-blue;
            padding-bottom: 0;

            .trust-markers-item-title {
              margin-top: 0;
            }
          }
        }
      }

      &[data-marker-style='black1'] {
        padding: fn.spacing(4) 0 fn.spacing(8);

        .trust-markers-markers {
          .trust-markers-item {
            border-right: 1px solid var.$color-neutral-grey-4;
            padding: 0 fn.spacing(4);
          }
        }
      }

      &[data-marker-style='black1'],
      &[data-marker-style='blue1'] {
        .trust-markers-markers {
          .trust-markers-item {
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: var.$breakpoint-xl) {
  .trust-markers-container {
    .trust-markers-content {
      &[data-marker-style='black1'] {
        .trust-markers-markers {
          .trust-markers-item {
            padding: 0 fn.spacing(8);

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
